@import 'shared';

.ImageWithLogo {
  position: relative;
  margin-bottom: px(40);

  @include media-desktop {
    margin-bottom: px(50);
  }

  .image {
    width: 100%;
  }

  .logo {
    max-width: px(120);
    max-height: px(60);
    position: absolute;
    object-fit: contain;
    object-position: left bottom;
    bottom: px(20);
    left: px(20);
    // filter: $filter-white drop-shadow(1px 1px 1px rgba($dark-grey, 0.5)); NOTE: Better a11y
    filter: $filter-white;

    @include media-desktop {
      max-width: px(180);
      max-height: px(120);
      bottom: px(30);
      left: px(30);
    }
  }
}
