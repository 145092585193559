@import 'shared';

.BlockGroup {
  @include general-spacing-styles;

  // NOTE: Special case for portafolio and team page
  &:global(:is(.team-bio-content, .team-bio-header, .portafolio-detail-header, .portafolio-detail-content)) {
    h1,
    h2,
    h3 {
      margin-bottom: px(20);

      @include media-desktop {
        margin-bottom: px(50);
      }
    }

    p {
      @include media-desktop {
        max-width: $extended-max-width;
      }
    }
  }

  &:global(.no-margin-top:first-child) {
    margin-top: 0;
  }
}
