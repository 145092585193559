@import 'shared';

.FreeForm {
  @include blog-wrapper;

  .content {
    margin-bottom: px(80);
    @include general-spacing-styles;

    @include media-desktop {
      margin-bottom: px(100);
    }

    figure,
    img {
      max-width: 100%;
      object-fit: contain;
      height: auto;
    }

    iframe {
      max-width: 100%;
      width: 100%;
      aspect-ratio: var(--aspect-ratio, 'none');
    }

    blockquote {
      position: relative;
      margin: px(20) 0;

      p {
        quotes: '"' '"';
        position: relative;

        &::before,
        &::after {
          color: $darker-gold;
          font-size: px(25);
          vertical-align: middle;
        }

        &::before {
          content: open-quote;
          padding-right: 2px;
        }

        &::after {
          content: close-quote;
          padding-left: 2px;
        }
      }
    }

    h1,
    h2,
    h3 {
      margin: px(60) 0 px(40);
    }

    h4,
    h5,
    h6 {
      margin: px(40) 0 px(20);
    }

    figure,
    img,
    video {
      max-width: 100%;
    }

    figure,
    video {
      margin: px(80) 0;
    }

    figcaption {
      margin-top: px(18);
      @include typography-label;
    }

    b,
    strong {
      font-weight: 700;
    }

    a {
      color: $darker-gold;
      text-decoration: underline;
      text-underline-position: under;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      span {
        font-size: unset;
        line-height: unset;
        letter-spacing: unset;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      b {
        font-weight: unset;
      }
    }

    span,
    p,
    li {
      @include typography-paragraph-2;
    }

    li {
      position: relative;
      margin-bottom: px(4);
    }

    ul {
      > li {
        &::before {
          content: '';
          position: absolute;
          border-radius: 50%;
          display: block;
          @include box(px(4));
          background-color: $darker-gold;
          top: calc(0.5em + 2px);
          left: px(-15);
        }
      }
    }

    ol {
      counter-reset: item;

      > li {
        &::before {
          position: absolute;
          display: block;
          content: counter(item) '. ';
          counter-increment: item;
          top: 0;
          left: px(-30 + 4);
          color: $darker-gold;
        }
      }
    }

    ol,
    ul {
      margin: px(20) 0 px(20) px(30);
    }
  }
}
