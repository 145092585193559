@import 'shared';

.AccordionItem {
  padding: px(32) 0;
  overflow: hidden;
  position: relative;

  .underline {
    @include underline($soft-grey);
  }

  @include media-tablet {
    padding: px(40) 0;
  }

  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'index title btn'
    'desc desc .'
    'content content .';
  grid-gap: px(10);
  align-items: center;

  @include media-tablet {
    grid-template-columns: 0.2fr 0.5fr 0.8fr 0.2fr;
    grid-template-areas:
      'index title desc btn'
      '. . content .';
    align-items: start;
  }

  .index {
    @include typography-h3;
    color: $gold;
    grid-area: index;
  }

  .title {
    grid-area: title;
    margin-bottom: 0;

    @include media-tablet {
      margin-right: 10%;
    }
  }

  .description {
    @include typography-paragraph-2;
    grid-area: desc;
    margin-top: px(24);
    margin-bottom: 0;

    @include media-tablet {
      margin-top: 0;
    }
  }

  .btnContainer {
    position: relative;
    grid-area: btn;
    align-self: flex-start;
    justify-self: flex-end;
  }

  .content {
    grid-area: content;
    @include typography-paragraph-3;

    &::before {
      content: '';
      display: block;
      height: px(12);

      @include media-tablet {
        height: px(24);
      }
    }
  }

  .title,
  .description,
  .content {
    word-break: break-word;
  }

  .description,
  .content {
    @include media-desktop {
      max-width: $default-max-width;
    }
  }

  &.hasList {
    padding-top: px(17);
    padding-bottom: 0;
    grid-gap: 0;
    position: relative;
    &:first-child {
      border-top: none;
    }
    .btnContainer {
      padding: $side-padding-mobile 0;
      @include position-100(absolute);
      max-height: px(64);
      @include z-index(pages);
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
    .bodyList {
      width: 100%;
      border-top: 1px solid $soft-grey;
      margin-top: px(17);
      li {
        @include typography-label;
      }
    }

    .accordionTrigger {
      padding: px(3);
    }

    .selectedNumber {
      margin-left: auto;
      color: $darker-gold;
      margin-right: px(20);
      @include typography-label;
      @include flex-center-vert;
    }
  }
}
