@import 'shared';

.SocialShare {
  @include flex-center-vert;
  flex-wrap: wrap;
  gap: px(24);

  a {
    display: inline-flex;
  }

  .text {
    color: $darker-gold;
    @include typography-paragraph-3;
  }

  .separator {
    width: px(24);
    height: 1px;
    background-color: $gold;
  }

  .button {
    // NOTE: Overriding the default button styles
    @include typography-paragraph-3;
  }

  .emailAnchor {
    display: inline-flex;
  }
}
