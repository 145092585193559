@import 'shared';

.filtersAccordion {
  @include circle-icon(true, true, false);
  padding: $side-padding-mobile;
  @include position-100(fixed);
  @include z-index(main-nav);
  pointer-events: none;
}
.overlay {
  background-color: $white;
  @include position-100(absolute);
  transform: translateY(-100%);
  pointer-events: none;
}
.accordion {
  @include z-index(main-nav);
  pointer-events: all;
  display: flex;
  flex-direction: column;
  height: 100%;
  visibility: hidden;

  .accordionSection {
    position: relative;
    flex: 1;
    overflow: auto;
  }

  .buttons {
    position: relative;
    display: grid;
    grid-gap: px(8);
    grid-template-columns: 1fr 1fr;
    margin-top: auto;
    @include z-index(main-nav);
    padding-top: px(24);
    border-top: 1px solid $soft-grey;

    &::after {
      content: '';
      @include position-100(absolute);
      height: px(100);
      top: unset;
      bottom: calc(100% + 1px);
      background: linear-gradient(0deg, $white, rgba($white, 0));
      @include z-index(main-nav);
      pointer-events: none;
    }

    .button {
      border: 1px solid $gold;
      border-radius: px(40);
      @include typography-label;
      padding: px(13);
      @include flex-center;
      cursor: pointer;

      .number,
      .close {
        background-color: $gold;
        @include box(px(23));
        color: $white;
        margin-left: px(12);
        border-radius: 50%;
        @include flex-center;
        .line {
          height: px(1.5);
          background-color: $white;
          width: 40%;
        }
      }
    }
    &.disabled {
      .button {
        border-color: $soft-grey;
        color: $soft-grey;
        pointer-events: none;
        .number,
        .close {
          background-color: $soft-grey;
        }
      }
    }
  }
  .close {
    @include reset-button;
    @include box(px(16));
    position: relative;
    .line {
      width: 100%;
      position: absolute;
      background-color: $black;
      height: 1px;
      transform: rotateZ(45deg);
      &:first-child {
        transform: rotateZ(-45deg);
      }
    }
  }

  .filterByInside {
    position: relative;
    border-bottom: 1px solid $soft-grey;
    @include flex-center-vert;
    justify-content: space-between;
    @include typography-caption;
    padding: px(8) 0;
    margin-bottom: px(24);
    .close {
      margin-right: px(5);
    }
  }
}

.filterList {
  display: flex;
  flex-wrap: wrap;
  pointer-events: all;

  h3 {
    margin-right: auto;
  }

  .circle {
    border-color: $gold;
    @include box(px(20));
    margin-right: px(8);
  }
  .filterItem {
    &:first-child {
      margin-top: px(32);
    }
    &:last-child {
      margin-bottom: px(32);
    }
  }
  .filterItem {
    @include flex-center-vert;
    margin: px(20) 0;
    cursor: pointer;

    .circle {
      svg {
        transform: none;
        width: px(8);
      }
    }

    &.active {
      .circle {
        background-color: $gold;
      }
    }
  }
}
