@import 'shared';

.PostGrid {
  --horizontal-grid-gap: #{px(16)};
  --vertical-grid-gap: #{px(70)};

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(#{px(336)}, 1fr));
  grid-gap: px(60) var(--horizontal-grid-gap);
  min-height: px(425);

  @include media-tablet {
    grid-gap: px(48) var(--horizontal-grid-gap);
  }

  // NOTE: Desktop has a special treatment for sizing
  @include media-desktop {
    grid-gap: px(32) var(--horizontal-grid-gap);

    &.disableVariableGrid {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: var(--vertical-grid-gap) var(--horizontal-grid-gap);
    }

    &:not(&.disableVariableGrid) {
      > *:nth-child(1) {
        grid-area: first;
      }

      > *:nth-child(2) {
        grid-area: second;
      }

      > *:nth-child(3) {
        grid-area: third;
      }

      > *:nth-child(4) {
        grid-area: fourth;
      }

      &.count-1 {
        grid-template-columns: 0.35fr 0.35fr 0.7fr 0.3fr;
        grid-template-areas: '. . first .';
      }

      &.count-2 {
        grid-template-columns: 0.35fr 0.35fr 0.5fr 0.5fr;
        grid-template-areas: '. . first second';
      }

      &.count-3 {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: '. first second third';
      }

      &.overFour {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: none;
        grid-gap: var(--vertical-grid-gap) var(--horizontal-grid-gap);

        > * {
          grid-area: auto;
        }
      }
    }
  }

  &.isCompaniesStyle {
    @include media-mobile {
      padding: 0 $side-padding-mobile;
      display: flex;
      gap: $side-padding-mobile;
      @include full-width-breakout;
      min-height: unset;
      padding-bottom: px(45);
      overflow: auto;
      @include discreet-scrollbar(true);

      > * {
        width: px(253);
        flex-shrink: 0;
        height: 100%;
        height: px(316);
      }
    }

    @include media-tablet {
      display: grid;
      gap: 0;
      grid-gap: var(--horizontal-grid-gap);
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-template-areas: 'first second' 'third fourth';
    }

    @include media-desktop {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        'first second third'
        'first second fourth';
    }
  }
}
