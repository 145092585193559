@import 'shared';

.PortfolioFilters {
  @include circle-icon(true, false, false);
  @include z-index(main-nav);
  position: relative;

  .filtersTopBar {
    display: grid;
    grid-template-columns: 1fr 2fr;
    @include media-tablet-landscape {
      grid-template-columns: 1fr 1fr;
    }
  }

  .selectText {
    @include typography-label;
    @include flex-center-vert;
    padding-right: px(15);
    color: $dark-grey;
    height: px(74);
    border-bottom: 1px solid $black;

    .clearFilters {
      @include reset-button;
      color: $darker-gold;
      border-bottom: 1px solid $darker-gold;
      margin-left: auto;
      @include flex-center-vert;
      @include typography-label;
      white-space: nowrap;
      padding-bottom: px(4);
      cursor: pointer;
      svg {
        width: px(12);
        margin-left: px(4);
      }
    }
  }

  .circle {
    .svg {
      transition: transform $default-duration $default-ease;
    }
  }
  .dropdown {
    @include reset-button;
    margin-right: 0;
    .circle {
      .svg {
        path {
          stroke-width: 2.5;
        }
      }
    }
  }

  .filtersWrapper {
    display: grid;
    background-color: $white;
    outline: 1px solid $black;
    height: calc(100% - 1px);
    grid-template-columns: 1fr 1fr;

    .filter {
      height: px(72);
      padding: px(8) 0;
      @include typography-label;
      position: relative;
      cursor: pointer;

      &.active {
        .filterName {
          pointer-events: none;
          .circle {
            background-color: $gold;
            border-color: transparent;

            .svg {
              transform: rotateZ(180deg);
              path {
                stroke: $white;
              }
            }
          }
        }
      }
      .filterName {
        @include position-100;
        @include reset-button;
        @include flex-center-vert;
        border-right: 1px solid rgba($dark-grey, 0.2);
        @include typography-label;
        padding: 0 px(15);
        position: relative;

        .selectedNumber {
          margin-left: auto;
          color: $darker-gold;
          margin-right: px(8);
        }
      }

      &:last-child {
        .filterList {
          width: calc(100% + 2px);
        }
      }
    }
  }

  .filterList {
    @include z-index(filter-nav);
    pointer-events: none;
    overflow: hidden;
    width: 100%;

    &:not(.mobileFilterList) {
      padding-top: px(72);
      position: absolute;
      top: -1px;
      left: -1px;
      border: 1px solid $black;
      width: calc(100% + 1px);
    }

    .filterListWrapper {
      position: relative;
      background-color: $white;
      padding: px(4) px(15);
      padding-bottom: px(15);
      pointer-events: all;
      cursor: initial;

      &::after {
        content: '';
        width: calc(100% - px(30));
        height: 1px;
        top: 0;
        position: absolute;
        background-color: $soft-grey;
      }
    }

    .circle {
      border-color: $gold;
      @include box(px(16));
      margin-right: px(8);
    }
    .filterItem {
      @include reset-button;
      @include typography-label;
      @include flex-center-vert;
      margin: px(20) 0;
      cursor: pointer;

      .circle {
        svg {
          transform: none;
          width: px(8);
        }
      }

      &.active {
        .circle {
          background-color: $gold;
        }
      }
    }
  }
  .mobileFilters {
    background-color: $white;
    .circle {
      border-color: $dark-grey;
    }

    .filterBy {
      @include typography-label;
      padding: px(18) px(20);
      border: 1px solid $dark-grey;
      @include flex-center-vert;
      justify-content: space-between;
      margin-bottom: px(40);
      @include z-index(pages);

      .circle {
        @include box(px(24));
        transform: rotateZ(-90deg);
        svg {
          @include box(px(14));
          path {
            stroke-width: 2;
          }
        }
      }
    }
  }
}
