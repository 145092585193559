@import 'shared';

.SliderButton {
  @include typography-label;
  display: flex;
  align-items: center;
  gap: px(12);
  border: 1px solid $soft-grey;
  border-radius: px(100);
  padding: px(8);

  .button {
    @include reset-button;
    @include box(px(30));
    border-radius: 50%;
    @include flex-center;
    background-color: transparent;
    color: $black;
    border: 1px solid $dark-grey;

    transition-property: background-color, color, border-color;
    transition-duration: $default-duration;
    transition-timing-function: $default-ease;

    &:disabled {
      opacity: 0.5;
    }

    svg {
      @include box(px(12));
      margin-top: -1px;
    }

    &:not(:disabled):hover {
      background-color: $gold;
      color: $white;
      border-color: transparent;
    }

    &.previous {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .text {
    display: flex;
    gap: px(5);
    color: $soft-grey;

    .number {
      display: block;
      min-width: 2.5ch;
      flex: 0;
      color: $dark-grey;

      &.active {
        text-align: right;
        color: $gold;
      }
    }
  }
}
