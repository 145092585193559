@import 'shared';

.ButtonGroup {
  display: flex;
  gap: px(8);

  &.horizontal {
    flex-wrap: wrap;
    align-items: center;
  }

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }

  &.right {
    justify-content: flex-end;
    align-items: flex-end;
  }
}
