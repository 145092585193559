@import 'shared';

.AudioPlayer {
  display: flex;
  gap: px(16);
  max-width: px(688);
  align-items: center;

  border: 1px solid $gold;
  border-radius: px(40);
  padding: px(14) px(16);

  .togglePlay {
    @include box(px(40));
    border-radius: 50%;
    background-color: $gold;
    @include flex-center;
    cursor: pointer;
  }

  .timeContainer {
    @include typography-label;
    display: flex;
    gap: px(6);
    min-width: 11ch;
    margin-right: -1ch;

    .time {
      color: $darker-gold;
    }
    .divider {
      color: $soft-grey;
    }
    .totalTime {
      color: $dark-grey;
    }
  }

  .progressBar {
    flex: 1;
    height: px(20);
    @include flex-center-vert;
    position: relative;
    cursor: pointer;

    .totalProgress {
      position: absolute;
      top: 50%;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: $soft-grey;
    }

    .progress {
      --scale: 0;
      position: absolute;
      top: 50%;
      left: 0;
      height: 4px;
      width: 100%;
      background-color: $gold;
      transform: scaleX(var(--scale)) translateY(-2px);
      transform-origin: left;
      transition: transform 0.5s ease-out;
    }
  }

  .toggleSound {
    @include box(px(40));
    border-radius: 50%;
    border: 1px solid rgba($dark-grey, 0.5);
    @include flex-center;
    cursor: pointer;
  }

  .pause {
    color: $white;
    width: px(10);
  }
  .play {
    color: $white;
    width: px(25);
  }

  .volumeOn,
  .volumeOff {
    color: $black;
    width: px(18);
  }

  .pause,
  .play,
  .volumeOff,
  .volumeOn {
    transition: transform $default-duration $default-ease;

    &:hover {
      transform: scale(1.1);
    }
  }
}
