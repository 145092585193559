@import 'shared';

.Separator {
  width: 100%;
  height: 1px;
  background-color: $black;
  border: none;
  margin: 0;
  transform-origin: left;

  &.isTopAbsolute {
    position: absolute;
    top: 0;
    left: 0;
  }

  &.isBottomAbsolute {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
