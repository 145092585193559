@import 'shared';

.Quote {
  @include container;
  @include media-mobile {
    margin-top: px(44);
  }

  > blockquote {
    position: relative;
    @include typography-h3;

    .svg {
      color: $gold;
      width: px(17);
      height: px(12);
      display: inline-block;

      &.open {
        position: absolute;
        left: 0;
        top: px(-22);

        @include media-tablet {
          top: px(6);
          left: px(-22);
        }
      }

      &.close {
        top: px(-6);
        position: relative;
        left: px(6);
        transform: rotate(-180deg);
      }
    }

    .quote {
      display: inline;
    }
  }

  > figcaption {
    margin-top: px(30);
    color: $darker-gold;
    @include typography-label;
  }
}
