@import 'shared';

.MediaText {
  --min-height-tablet: #{$min-height-media-text-tablet};
  --min-height-desktop: #{$min-height-media-text-desktop};

  @include media-mobile {
    padding-top: $vertical-padding-mobile;
    padding-bottom: $vertical-padding-mobile;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: px(60);

  @include general-spacing-styles;
  @include general-max-width;
  position: relative;

  @include media-tablet {
    gap: px(16);
    display: grid;
    grid-template-columns: minmax(0, 0.7fr) minmax(0, 1fr);
    grid-template-areas: 'text media';

    &.flipOrder {
      grid-template-areas: 'media text';
    }
  }

  &.alignCenter {
    .textContainer {
      justify-content: center;
    }
  }

  &.alignBottom {
    .textContainer {
      justify-content: flex-end;
    }
  }

  &.gold {
    @include media-tablet {
      grid-template-columns: 3fr 2fr;
      &.flipOrder {
        grid-template-columns: 2fr 3fr;
      }
    }
    hr {
      background-color: #ac7908;
    }
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-area: text;

    @include media-tablet {
      position: relative;
      padding: px(52) 0;
      margin-right: 5%;
    }
  }

  .mediaContainer {
    grid-area: media;

    .image {
      @include box(100%);

      img {
        @include box(100%);

        @include media-tablet {
          min-height: var(--min-height-tablet);
        }

        @include media-desktop {
          min-height: var(--min-height-desktop);
        }
      }
    }
  }

  &:global(.no-mobile-border-top) {
    @include media-mobile {
      .borderTop {
        display: none;
      }
      padding-top: 0;
    }
  }

  &:global(.no-mobile-border-bottom) {
    @include media-mobile {
      .bottomBorder {
        display: none;
      }
      padding-bottom: 0;
    }
  }

  &:global(.no-desktop-border-top) {
    .textContainer {
      @include media-tablet {
        .topBorder {
          display: none;
        }
        padding-top: px(30);
      }
    }
  }

  &:global(.no-desktop-border-bottom) {
    .textContainer {
      @include media-tablet {
        .bottomBorder {
          display: none;
        }
        padding-bottom: px(30);
      }
    }
  }
}
