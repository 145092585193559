@import 'shared';

.PressItemHandler {
  --accent-color: #{$darker-gold};

  &.isLoading {
    min-height: max(px(700), 90vh);
  }

  .mediaText {
    --min-height-desktop: #{px(726)};
  }

  .featured {
    color: var(--accent-color);
    margin-bottom: px(114);
  }

  .author {
    @include typography-label;
    margin-bottom: px(24);
    color: var(--accent-color);

    svg {
      color: $gold;
      @include box(px(13));
      margin: 0 px(6);
    }
  }

  .type {
    display: inline-flex;
    margin-left: px(6);
    color: $text-grey;
  }

  .divider {
    color: $soft-grey;
  }

  .title {
    margin-bottom: px(40);
    margin-right: 2ch;
  }
}
