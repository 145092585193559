@import 'shared';

.ListSlotMachine {
  width: 100%;
  margin-top: px(40);

  .list {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    > li[aria-hidden='true'] {
      position: relative;
      opacity: 0;
      visibility: hidden;
    }

    > li {
      display: block;
      padding: px(10) 0;
      width: 100%;
      @include typography-h1;
      position: absolute;
      color: $gold;
    }
  }
}
