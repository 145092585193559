@import 'shared';

.Carousel {
  display: flex;
  flex-direction: column;
  gap: px(16);

  @include media-tablet {
    display: grid;
    grid-template-columns: minmax(0, 0.7fr) minmax(0, 1fr);
  }

  .sliderButton {
    margin-bottom: px(40);
  }

  .leftContainer {
    position: relative;
    width: 100%;
    padding: px(40) 0 px(80);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @include media-tablet {
      padding: px(40) 0;
    }
  }

  .firstCarousel {
    .wrapper {
      @include media-tablet {
        transform: none !important; // NOTE: Special case to reset swiper when breakpoints change
      }
    }

    @include media-tablet {
      :global(.swiper-wrapper) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 'item';
      }

      :global(.swiper-slide) {
        transition: opacity $carousel-duration $horizontal-shift-ease;
        opacity: 0;
        grid-area: item;
      }

      :global(.swiper-slide-prev) {
        transition-delay: 0.125s;
        opacity: 0;
      }

      :global(.swiper-slide-next) {
        transition-delay: 0.125s;
        opacity: 0;
      }

      :global(.swiper-slide-active) {
        transition-delay: $carousel-duration;
        opacity: 1;
      }
    }
  }

  .secondCarousel {
    display: none;

    @include media-tablet {
      display: block;
    }

    .wrapper {
      height: 100%;

      @include media-tablet {
        min-height: $min-height-media-text-tablet;
      }

      @include media-desktop {
        min-height: $min-height-media-text-desktop;
      }

      .slide {
        height: 100%;

        .imageDesktop {
          @include box(100%);

          img {
            @include box(100%);
          }
        }
      }
    }
  }

  .swiper {
    width: 100%;
    overflow: hidden;
  }

  .wrapper {
    display: flex;
    flex-wrap: nowrap;
  }

  .slide {
    flex-shrink: 0;
  }

  .title {
    margin-bottom: px(25);
    @include typography-h3;
    max-width: px(453);
    margin-right: 10%;
  }

  .description {
    margin-bottom: px(34);
    @include typography-paragraph-2;
    max-width: px(430);
    margin-right: 10%;
  }

  .imageMobile {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: px(34);

    img {
      width: 100%;
      min-height: px(293);
    }

    @include media-tablet {
      display: none;
    }
  }

  .imageDesktop {
    display: none;
    width: 100%;
    height: auto;

    @include media-tablet {
      display: block;
    }
  }

  .imageLogo {
    filter: $filter-gold;
    max-width: px(180);
    max-height: px(60);
    object-fit: contain;
    object-position: left bottom;
    margin-bottom: px(30);
  }

  :global(.swiper-slide) {
    position: relative;
  }

  :global(.swiper-slide-active) {
    z-index: 1;
  }

  :global(.swiper-slide-prev) {
    z-index: 0;
  }

  &:global(.no-mobile-border-top) {
    .leftContainer {
      @include media-mobile {
        padding-top: 0;
        .topBorder {
          display: none;
        }
      }
    }
  }

  &:global(.no-mobile-border-bottom) {
    .leftContainer {
      @include media-mobile {
        padding-bottom: 0;
        .bottomBorder {
          display: none;
        }
      }
    }
  }

  &:global(.no-desktop-border-top) {
    .leftContainer {
      @include media-tablet {
        .topBorder {
          display: none;
        }
      }
    }
  }

  &:global(.no-desktop-border-bottom) {
    .leftContainer {
      @include media-tablet {
        .bottomBorder {
          display: none;
        }
      }
    }
  }
}
