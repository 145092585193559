@import 'shared';

.VideoEmbed {
  position: relative;
  height: auto;
  width: 100%;
  aspect-ratio: 16 / 9;

  .poster {
    @include rect(absolute, 0, 0, 100%, 100%);

    figure {
      @include rect(absolute, 0, 0, 100%, 100%);

      img {
        @include box(100%);
        object-fit: cover;
        max-height: unset;
      }
    }

    button {
      position: absolute;
      z-index: 2;
      @include transform-center;
    }
  }

  .vimeoEl {
    @include rect(absolute, 0, 0, 100%, 100%);
    background-color: $black;
    @include flex-center-vert;

    & > * {
      width: 100%;
    }

    iframe {
      @include box(100%);
    }
  }
}
