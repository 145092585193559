@import 'shared';

.TeamGrid {
  --mobile-default: #{px(164)};
  --mobile-default-tall: #{px(246)};
  --tablet-default: #{px(268)};
  --tablet-default-tall: #{px(402)};
  --desktop-default: #{px(336)};
  --desktop-default-tall: #{px(505)};
  --rollover-duration: 0.397s;
  --rollover-ease: cubic-bezier(0.5, 0, 0, 1);
  --rollover-off-ease: cubic-bezier(0.02, 0.04, 0.08, 0.94);
  --arrow-hover-duration: 0.5s;

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: px(60) px(16);

    @include media-tablet {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-desktop {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: px(112) px(16);
    }
  }

  .item {
    &.displayNone {
      display: none;
    }

    &:nth-child(12n + 1),
    &:nth-child(12n + 8),
    &:nth-child(12n + 10) {
      .profilePic {
        img {
          height: var(--mobile-default-tall);

          @include media-tablet {
            height: var(--tablet-default-tall);
          }

          @include media-desktop {
            height: var(--desktop-default-tall);
          }
        }
      }
    }
  }

  .profilePic {
    margin-bottom: px(10);
    overflow: hidden;

    img {
      transition: $image-transition;
      transform: $image-scale;
      width: 100%;
      height: var(--mobile-default);
      object-position: top;

      @include media-tablet {
        height: var(--tablet-default);
      }

      @include media-desktop {
        height: var(--desktop-default);
      }
    }
  }

  .position {
    margin-bottom: 0;
    @include typography-label;
    color: $dark-grey;

    @include media-desktop {
      margin-bottom: px(5);
    }
  }

  .name {
    width: 100%;
    @include flex-center-vert;
    justify-content: space-between;
    margin-bottom: px(10);
    @include typography-paragraph-3;

    @include media-desktop {
      @include typography-paragraph-2;
    }
  }

  .rollover {
    display: block;
    width: 100%;
    height: 1px;
    background: $gold;
    transition: transform var(--rollover-duration) var(--rollover-off-ease);
    transform: scaleX(0);
    transform-origin: right;
  }

  .svg {
    opacity: 0;
    transition: opacity var(--arrow-hover-duration) linear calc(var(--arrow-hover-duration) / 2);
    @include box(px(10));
    color: $black;
  }

  .item {
    &:hover {
      .svg {
        opacity: 1;
      }

      .rollover {
        transition-timing-function: var(--rollover-ease);
        transform: scaleX(1);
        transform-origin: left;
      }

      .profilePic {
        cursor: pointer;

        img {
          transform: scale(1);
        }
      }
    }
  }
}
