@import 'shared';

.CoverHero {
  @include full-width-breakout;
  position: relative;
  margin-bottom: px(31);

  // NOTE: Need this in css cause is above the fold
  opacity: 0;

  @include media-desktop {
    margin-bottom: px(84);
  }

  &::after {
    @include position-100(absolute);
    pointer-events: none;
    content: '';
    background: linear-gradient(180deg, rgba($black, 0.4) 0%, rgba($black, 0) 100%),
      linear-gradient(0deg, rgba($gold, 0.15), rgba($gold, 0.15));
    mix-blend-mode: multiply;
  }

  &.limitHeight {
    .image,
    .video {
      height: px(400);
    }
  }

  .image,
  .video {
    width: 100%;
  }

  @include media-mobile {
    margin-bottom: px(40);
  }
}
