@import 'shared';

.Gallery {
  display: grid;
  gap: px(16);

  @include media-tablet {
    grid-template-columns: auto auto auto;
  }

  .image {
    margin-bottom: px(16);
  }

  .caption {
    color: $darker-gold;
    @include typography-label;
  }
}
