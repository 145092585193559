@import 'shared';

.PlayButton {
  --bg-color: #{$white};
  --text-color: #{$black};
  --svg-bg-color: #{$gold};
  --svg-play-color: #{$white};

  display: flex;
  align-items: center;
  gap: px(16);
  padding: px(12) px(16) px(12) px(24);
  border-radius: px(40);
  border: none;
  background-color: var(--bg-color);
  color: var(--text-color);
  @include typography-play-button;

  transition-property: color, background-color;
  transition-timing-function: $default-ease;
  transition-duration: $default-duration;

  @include media-desktop {
    gap: px(15);
    padding: px(14) px(14) px(14) px(30);
    border-radius: px(100);
  }

  &:hover {
    cursor: pointer;

    --bg-color: #{$gold};
    --text-color: #{$white};
    --svg-bg-color: #{$white};
    --svg-play-color: #{$gold};
  }
}

.circle {
  @include box(px(24));
  background-color: var(--svg-bg-color);
  transition: background-color $default-ease $default-duration;
  border-radius: 50%;
  @include flex-center;

  @include media-desktop {
    @include box(px(40));
  }
}

.svg {
  transition: color $default-ease $default-duration;
  color: var(--svg-play-color);
  @include box(px(15));

  @include media-desktop {
    @include box(px(25));
  }
}
