@import 'shared';

.Spacer {
  min-height: px(20);
  height: calc(var(--spacer-height) - #{px(20)});

  @include media-tablet {
    height: var(--spacer-height);
  }
}
