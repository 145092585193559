@import 'shared';

.ScrollButton {
  --bg-svg: #{transparent};
  --color-svg: #{$black};
  --border-color: #{$gold};

  @include media-mobile {
    display: none;
  }

  @include reset-button;
  display: flex;
  gap: px(16);
  align-items: center;
  cursor: pointer;
  @include font-size(16, 16);
  .circle {
    @include box(px(40));
    background-color: var(--bg-svg);
    border: 1px solid var(--border-color);
    @include flex-center;
    border-radius: 50%;

    transition-property: background-color, border-color;
    transition-duration: $default-duration;
    transition-timing-function: $default-ease;
  }

  .svg {
    transition: color $default-duration $default-ease;
    @include box(px(15));
    color: var(--color-svg);
  }

  &:disabled {
    cursor: auto;
  }

  &:not(:disabled):hover {
    --bg-svg: #{$gold};
    --color-svg: #{$white};
  }
}
