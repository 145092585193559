@import 'shared';

.PortfolioItem {
  width: 100%;
  background-color: $white;
  outline: 1px solid $soft-grey;

  .container {
    height: px(200);
    position: relative;
    display: block;

    @include media-tablet {
      padding-top: 100%;
    }

    .bottomWrapper {
      margin-top: auto;
      display: flex;
      width: 100%;
      justify-content: space-between;

      .accordionTrigger {
        @include box(px(24));
        margin-left: auto;
        span {
          width: px(14);
        }
      }
    }

    .cat {
      @include flex-center-vert;
      @include typography-label;
      @include z-index(pages);

      .circle {
        @include box(px(15));
        border-radius: 50%;
        background: $gold;
        @include flex-center;
        margin-right: px(5);

        svg {
          @include box(px(5));
        }
      }
    }
    .topWrapper {
      position: absolute;
      pointer-events: none;
      top: px(20);
      left: px(20);
      width: calc(100% - px(40));
      display: flex;
      justify-content: space-between;
      p {
        margin: 0;
        margin-right: px(4);
        white-space: nowrap;
      }
      .tickerWrapper {
        color: $darker-gold;
        .circle {
          svg {
            @include box(px(8));
          }
        }
      }
    }

    .front {
      @include position-100(absolute);
      padding: px(20);
      @include flex-center;

      .logo {
        max-width: px(135);
        max-height: px(100);
        @include media-tablet {
          max-width: px(125);
          max-height: px(115);
        }
        @include media-desktop {
          max-height: px(140);
          max-width: px(155);
        }
      }
    }

    .back {
      @include position-100(absolute);
      padding: px(16) px(20);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include z-index(pages);
      @include media-tablet {
        padding: px(20);
      }
      .linkOverlay {
        @include reset-button;
        z-index: 1;
        @include position-0;
        @include box(calc(100% - 2px));
        position: absolute;
        display: block;
      }
      .button.hidden {
        pointer-events: none;
      }

      .bg {
        visibility: hidden;
        @include position-100(absolute);
        @include box(calc(100% - 1px));
        background-color: $soft-gold;
      }

      .text {
        // max-height: px(80);
        @include text-ellipsis(3, 5, 7);
        @include typography-paragraph-2;
        margin-bottom: px(10);

        @include media-desktop {
          margin-bottom: px(18);
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}
