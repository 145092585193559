@import 'shared';

.AccordionTrigger {
  cursor: pointer;
  @include box(px(32));
  border: 1px solid $dark-grey;
  pointer-events: all;
  border-radius: 50%;
  background-color: transparent;
  color: $black;
  position: relative;

  transition-property: background-color, border-color, color;
  transition-duration: $default-duration;
  transition-timing-function: $default-ease;

  @include media-tablet {
    @include box(px(40));
    span {
      @include box(px(14), px(1.5));
    }
  }

  span {
    position: absolute;
    @include box(px(11), px(1));
    background-color: currentColor;
    display: block;
    left: 50%;
    top: 50%;
    transition: transform $default-duration $default-ease;
  }

  span:nth-child(1) {
    transform: translateX(-50%) translateY(-50%);
  }

  span:nth-child(2) {
    transform: translateX(-50%) translateY(-50%) rotate(-270deg);
  }
  &.hasDropdownSvg {
    padding: 0;
    @include flex-center;
    svg {
      transition: transform $default-duration $default-ease;
      @include box(70%);
    }
  }

  &.isOpen {
    background-color: $gold;
    border: 1px solid $gold;
    color: $white;

    span:nth-child(1) {
      transform: translateX(-50%) translateY(-50%) rotate(180deg);
    }

    span:nth-child(2) {
      transform: translateX(-50%) translateY(-50%);
    }

    svg {
      transform: rotate(180deg);
    }
  }
}
