@import 'shared';

.Pagination {
  @include flex-center;

  :global {
    .pagination {
      margin-top: $vertical-padding-mobile;

      @include media-desktop {
        margin-top: $vertical-padding-desktop;
      }

      @include flex-center;
      flex-wrap: wrap;
      gap: px(20);
    }

    .pagination-labels {
      @include font-size(16, 16);
      cursor: pointer;
      align-self: stretch;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: currentColor;
      }

      &:hover:not(.pagination-disabled) {
        &::before {
          background-color: $darker-gold;
        }
      }

      &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: $soft-gold;
      }
    }

    .pagination-previous-item {
      position: relative;
      margin-right: px(20);

      &::after {
        right: px(-20);
      }
    }

    .pagination-next-item {
      position: relative;
      margin-left: px(20);

      &::after {
        left: px(-20);
      }
    }

    .pagination-break {
      @include font-size(16, 16, -0.01em);
      color: $dark-grey;
      cursor: pointer;
      align-self: flex-end;
    }

    .pagination-disabled {
      cursor: auto;
      opacity: 0.5;
    }

    .pagination-page-container {
      border: 1px solid rgba($black, 0.2);
      border-radius: 50%;
      @include box(px(24));
      position: relative;
      color: $darker-gold;
      cursor: pointer;
      transition-property: background-color, color, border-color;
      transition-duration: $default-duration;
      transition-timing-function: $default-ease;

      &:hover {
        background-color: $darker-gold;
        border: 1px solid $darker-gold;
        color: $white;
      }
    }

    .pagination-page-link {
      position: absolute;
      @include typography-label;
      @include box(100%);
      @include flex-center;
    }

    .pagination-active {
      background-color: $darker-gold;
      border: 1px solid $darker-gold;
      color: $white;
    }
  }
}
