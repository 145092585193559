@import 'shared';

.CompanyItem {
  --color: #{$white};

  @mixin motion {
    .container {
      transform: translateY(-45px);
    }

    .btn {
      transition-delay: $default-duration / 2;
      opacity: 1;
    }

    .shade {
      opacity: 0.7;
    }

    .image {
      img {
        transform: scale(1);
      }
    }
  }

  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  @include media-tablet {
    min-height: px(429);
  }

  @include media-desktop {
    min-height: px(307);
  }

  .container {
    overflow: hidden;
    padding: px(20) px(10);
    position: relative;
    transition: transform $default-duration $horizontal-shift-ease;

    @include media-tablet {
      padding: px(34) px(24);
    }
  }

  .imageLogo {
    max-width: px(120);
    max-height: px(46);
    object-fit: contain;
    object-position: left bottom;
    filter: $filter-white;
    margin-bottom: px(3);
  }

  .excerpt {
    @include typography-paragraph-2;
    color: var(--color);
    margin-bottom: px(12);
    margin-right: 5%;

    @include media-desktop {
      @include font-size(18, 26);
    }
  }

  .btn {
    margin-top: 0;
    color: var(--color);
    position: absolute;
    z-index: 1;
    bottom: px(20);
    left: px(10);
    transition: opacity $default-ease $default-duration;
    opacity: 0;

    @include media-tablet {
      bottom: px(34);
      left: px(24);
    }
  }

  .image {
    z-index: -2;
    top: 0;
    left: 0;
    position: absolute;
    @include box(100%);

    img {
      @include box(100%);
      transform: $image-scale;
      transition: $image-transition;
      max-height: unset !important;
    }
  }

  .shade {
    top: 0;
    left: 0;
    z-index: -1;
    position: absolute;
    @include box(100%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(0deg, rgba(172, 121, 8, 0.25), rgba(172, 121, 8, 0.25));
    mix-blend-mode: multiply;
    transition: opacity $default-duration $image-hover-ease;
  }

  &.inverse {
    --color: #{$black};
    outline: 1px solid $dark-grey;
  }

  @media (hover: none) {
    @include motion;
  }

  @include detect-hover {
    &:hover {
      @include motion;
    }
  }
}
