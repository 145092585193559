@import 'shared';

.DefaultBlock {
  padding: px(20);
  outline: 2px solid $darker-gold;
  width: fit-content;

  p {
    @include typography-paragraph-2;
    display: inline;

    span {
      padding: px(3);
      background-color: $darker-gold;
      color: $white;
    }
  }

  pre {
    @include font-size(16, 16);
  }
}
