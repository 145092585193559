@import 'shared';

.FilterBar {
  // TODO: Make sticky when all navbars are aligned
  // position: sticky;
  // top: $navbar-height-mobile;
  margin-bottom: px(60);
  // background-color: $white; // TODO: Update with bg color
  border-top: 1px solid $soft-grey;
  border-bottom: 1px solid $soft-grey;
  z-index: 1;
  white-space: nowrap;

  &.isLeftAligned {
    @include media-tablet {
      .filterWrapper {
        gap: 0;

        .spacer {
          flex: 0;
        }

        .groups {
          flex: 1;
        }
      }
    }
  }

  .filterWrapper {
    display: flex;

    flex-wrap: nowrap;
    height: px(60);

    .spacer {
      flex: 0;
    }

    .groups {
      display: flex;
      gap: px(48);
      flex: 1;
      overflow: auto;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        display: none;
      }

      > * {
        scroll-snap-align: start;
      }
    }

    @include media-tablet {
      gap: px(16);

      .spacer {
        flex: 0.7;
      }

      .groups {
        flex: 1;
      }
    }
  }

  &.isScrollable {
    @include right-bleed-breakout;

    .category {
      &:last-child {
        margin-right: $side-padding-mobile;

        @include media-tablet {
          margin-right: $side-padding-tablet;
        }
      }
    }
  }

  // @include media-mobile {
  //   @include right-bleed-breakout;

  //   .category {
  //     &:last-child {
  //       margin-right: $side-padding-mobile;
  //     }
  //   }
  // }

  @include media-tablet {
    justify-content: center;
  }

  @include media-desktop {
    // top: $navbar-height-desktop;
    margin-bottom: px(80);
  }

  .category {
    @include reset-button;
    @include typography-label;
    color: $black;
    cursor: pointer;
    height: 100%;
    @include flex-center;
    border-bottom: 2px solid transparent;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: px(3);
      bottom: px(-2);
      position: absolute;
      background-color: $gold;
      transform-origin: center;
      transform: scaleX(0);
      transition: transform $default-duration $default-ease;
    }

    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }

    .count {
      position: relative;
      color: $gold;
      margin-left: px(13);
      isolation: isolate;
      transition: color $default-duration $default-ease;

      &::before {
        content: '';
        @include box(px(23));
        position: absolute;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid rgba($black, 0.2);
        z-index: -1;
        @include transform-center;
        transition-property: background-color, border-color;
        transition-duration: $default-duration;
        transition-timing-function: $default-ease;
      }
    }

    &.active {
      transition: color $default-duration $default-ease;
      color: $gold;

      &::after {
        transform: scaleX(1);
      }

      .count {
        color: $white;

        &::before {
          background-color: $gold;
          border-color: $gold;
        }
      }
    }
  }
}
