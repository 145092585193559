@import 'shared';

.PortfolioGrid {
  width: 100%;
  height: 100%;
  margin-top: px(40);

  @include breakpoint(desktop) {
    margin-top: px(80);
  }

  .grid {
    display: grid;
    flex-wrap: wrap;
    margin-bottom: px(80);
    @media (min-width: 540px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 670px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include media-tablet-landscape {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-bottom: px(100);
    }
    .gridItem {
      visibility: hidden;
    }
  }

  .noMatch {
    @include flex-center;
    margin: px(80) 0;
  }
}
