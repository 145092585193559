@import 'shared';

.LoadingItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  @include min-height-by-breakpoints;

  > *:last-child {
    margin-bottom: 0;
  }

  .categories,
  .image,
  .info > div,
  .title,
  .btn {
    border: 1px solid rgba($black, 0.1);
    border-radius: px(4);
  }

  .categories {
    background-color: rgba($gold, 0.2);
  }
  .image {
    background-color: rgba($dark-grey, 0.2);
  }
  .info {
    > div {
      background-color: rgba($gold, 0.2);
    }
  }
  .title {
    background-color: rgba($black, 0.1);
  }
  .btn {
    background-color: rgba($dark-grey, 0.2);
  }

  .categories {
    @include typography-label;
    margin-bottom: px(18);

    @include minimum-lines(1);
    min-width: 20%;
  }

  .image {
    width: 100%;
    margin-bottom: px(20);
    aspect-ratio: 1.46 / 1;
  }

  .info {
    @include minimum-lines(1);
    @include typography-label;
    margin-bottom: px(16);
    min-width: 50%;

    display: flex;
    gap: px(8);

    > div {
      width: px(100);
    }

    .divider {
      color: $soft-grey;
    }
  }

  .title {
    @include typography-paragraph-1;
    margin-bottom: px(5);

    @include media-desktop {
      @include typography-paragraph-2;
    }

    min-width: 90%;
    @include minimum-lines(2);
  }

  .btn {
    margin-top: auto;
    min-width: px(125);
    min-height: px(32);
  }

  a {
    pointer-events: none;
  }
}
