@import 'shared';

.Image {
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
    object-fit: cover;
    max-height: vh(100);

    &.full {
      @include full-width-breakout;
      max-width: unset;
    }

    &.wide {
      width: 100%;
    }

    &.left {
      margin-right: auto;
    }

    &.right {
      margin-left: auto;
    }

    &.center {
      margin-left: auto;
      margin-right: auto;
    }

    &.parallax {
      transform: scale(1.2);
    }
  }

  figcaption {
    @include typography-caption;
    color: $darker-gold;
    margin-top: px(16);
  }

  &.fillContainer {
    height: 100%;

    img {
      max-height: 100%;
      height: 100%;
    }
  }
}