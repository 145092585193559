@import 'shared';

.PostItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  @include min-height-by-breakpoints;

  &:hover {
    cursor: pointer;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  .categories {
    color: $darker-gold;
    @include typography-label;
    margin-bottom: px(18);
    min-height: 1em;
  }

  .image {
    width: 100%;
    background-color: $pure-black;
    margin-bottom: px(16);

    img {
      width: 100%;
      aspect-ratio: 1.46 / 1;
      object-fit: contain;
    }

    @at-root .wide#{&} {
      margin-bottom: px(30);
    }
  }

  .info {
    color: $dark-grey;
    @include typography-label;
    display: flex;
    align-items: center;
    gap: px(8);
    margin-bottom: px(12);

    @at-root .wide#{&} {
      margin-bottom: px(30);
    }

    svg {
      color: $gold;
      @include box(px(13));
    }

    .divider {
      color: $soft-grey;
    }
  }

  .title {
    @include typography-paragraph-1;
    margin-bottom: px(6);
    margin-right: 10%;

    @at-root .wide#{&} {
      margin-bottom: px(20);

      @include media-desktop {
        @include typography-h4;
      }
    }

    @include media-tablet {
      margin-right: 0;
    }

    @include media-desktop {
      @include typography-paragraph-2;
    }
  }

  > a {
    pointer-events: none;
    margin-top: auto;
  }
}
