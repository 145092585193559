@import 'shared';

.BlogItemHandler {
  --accent-color: #{$darker-gold};

  &.isLoading {
    min-height: max(px(700), 90vh);
  }

  .mediaText {
    --min-height-desktop: #{px(726)};
  }

  .featured {
    color: var(--accent-color);
    margin-bottom: px(114);
  }

  .author {
    color: var(--accent-color);
    @include typography-label;
    margin-bottom: px(24);
  }

  .title {
    margin-bottom: px(40);
    margin-right: 2ch;
  }
}
